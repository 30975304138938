<script>
  import { bingo } from "./stores";

  //export let items;

  //$: bingo = items && shuffle(items);

  //console.log("bingo=", $bingo);

  $: if ($bingo) {
    document.title = `Boss Bingo ${$bingo.title}`;
    document.documentElement.style.setProperty(
      "--bingo-image",
      `url(/${$bingo.image})`
    );
    document.documentElement.style.setProperty("--bingo-color", $bingo.color);
  }
</script>

<main>
  {#if $bingo}
    <header>
      <h1>Bingo like&nbsp;a&nbsp;boss.</h1>
      <h2>{$bingo.title}</h2>
    </header>

    <ul>
      {#each Object.entries($bingo.items.slice(0, 12)) as [i, text]}
        <li>
          <input type="checkbox" id="check-{i}" /><label for="check-{i}"
            >{text}</label
          >
        </li>
      {/each}
      <li>
        <input type="checkbox" id="check-free" checked disabled /><label
          for="check-free"
        />
      </li>
      {#each Object.entries($bingo.items.slice(12)) as [i, text]}
        <li>
          <input type="checkbox" id="check-{i}" /><label for="check-{i}"
            >{text}</label
          >
        </li>
      {/each}
    </ul>
  {/if}
</main>
