import { writable, derived, readable } from "svelte/store";

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const game = readable(
  (function (params) {
    return params.get("game") || params.get("bingo");
  })(new URLSearchParams(window.location.search)) ??
    window.location.pathname.replace("/", "")
);

export const bingo = derived(game, async ($game, set) => {
  set(null);
  var res = await fetch(`${$game}.json`);

  if (res.ok) {
    //console.log("ok");
    var json = await res.json();
    //console.log("json=", json);
    if (!json.items) json = { items: json };
    set({ ...json, items: shuffle(json.items) });
  }
});

// writable([
//     "Amenity Boss",
//     "Hand Sanitizer",
//     "Adding or Deleting Users",
//     "Face Mask",
//     "Issuing a Violation",
//     "Help Chat",
//     "Assigning a Smart Decal",
//     "Pet Lover",
//     "Wearing GREEN",
//     "Passcodes",
//     "Coffee Lover",
//     "Zoom Pro",
//     "Community Boss",
//     "Notify",
//     "Amenity Boss Training",
//     "Adding Special Closures",
//     "Ate Local",
//     "Went to Starbucks",
//     "New Quarantine Hobby",
//     "Guest Parking",
//     "Parking Boss",
//     "Editing Smart Decals",
//     "Turn Over Apartments",
//     "Decorated for St. Patricks Day",
// ]);
